<template>
	<div>
        <Toast />
        <DataTable :value="tickets" dataKey="path" :loading="loading" :paginator="true" :rows="20"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[20,50,100]"
            :currentPageReportTemplate="$t('Showing {first} to {last} of {totalRecords} entries')">
            <template #loading>
                Loading customers data. Please wait.
            </template>
            <Column headerStyle="width: 3em">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        <Button icon="pi pi-search" class="p-button-rounded" @click="showTicket(slotProps.data)"/>
                    </div>
                </template>
            </Column>
            <Column headerStyle="width: 6em" field="ticket.hid" header="#" :sortable="true" >
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        {{slotProps.data.ticket.hid}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['ticket.hid']" class="p-column-filter" @input="filterTickets()"/>
                </template>
            </Column>
            <Column headerStyle="width: 12em" field="ticket.created" :header="$t('Created')" :sortable="true">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-center p-ai-center">
                        {{new Date(slotProps.data.ticket.created).toLocaleString()}}
                    </div>
                </template>
            </Column>
            <Column :header="$t('Type')" :sortable="true" filterField="ticket.type" filterMatchMode="contains">
                <template #body="slotProps">
                    <div class="p-d-flex p-jc-center p-ai-center">
                        {{slotProps.data.ticket.type}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['ticket.type']" class="p-column-filter" @input="filterTickets()"/>
                </template>
            </Column>
            <Column field="ticket.assignee" :header="$t('Assignee')" :sortable="true" >
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        {{slotProps.data.ticket.assignee || $t('Unassigned')}}
                    </div>
                </template>
                <template #filter>
                    <InputText type="text" v-model="filters['ticket.assignee']" class="p-column-filter" @input="filterTickets()"/>
                </template>
            </Column>
            <Column headerStyle="width: 12em" field="ticket.lastActivity" :header="$t('Last Activity')" :sortable="true">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        {{slotProps.data.ticket.lastActivity ? new Date(slotProps.data.ticket.lastActivity).toLocaleString() : 'N/A'}}
                    </div>
                </template>
            </Column>
            <Column headerStyle="width: 4em" field="ticket.active" :header="$t('Open')" :sortable="true" :filterFunction="(v,f) => f?v:true">
                <template #body="slotProps">
                    <div class="p-d-flex p-ai-center p-jc-center">
                        <i v-if="slotProps.data.ticket.active" class="pi pi-circle-on"/>
                        <i v-else class="pi pi-circle-off"/>
                    </div>
                </template>
                 <template #filter>
                     <div class="p-d-flex p-jc-center p-ai-center">
                        <Checkbox v-model="filters['ticket.active']" :binary="true" @change="filterTickets()"/>
                     </div>
                </template>
            </Column>
        </DataTable>
        <Dialog :header="$t('Notice')" :visible.sync="displayGenericDialog" :dismissableMask="true" :style="{width: '600px'}" :modal="true" :closable="false">
            {{$t(genericDialogText)}}
            <template #footer>
                <div class="p-mt-4">
                    <Button class="p-mr-2" :label="$t('Ok')" @click="displayGenericDialog = false" />
                </div>
            </template>
        </Dialog>
        <div v-if="currentTicket" >
            <Dialog :header="currentTicket.ticket.hid" :visible.sync="displayModal" :style="{width: '80vw'}" :modal="true" :closable="true" @show="resolution = null">
                <Toolbar>
                    <template #left>
                        <Dropdown class="p-d-inlineflex p-ai-center p-mr-2 p-p-2" style="height: 37px; text-align: left; width: 300px;"  v-model="currentTicket.ticket.assignee" :options="adminUsersList" optionLabel="label" optionValue="value" :placeholder="$t('Select Assignee')" :showClear="true" />
                    </template>
                </Toolbar>
                <Panel class="p-mt-5" :header="$t('Ticket Details')">
                    <FormViewer v-if="displayModal" :showSave="false" :form="sortForm" />
                </Panel>
                <HistoryViewer :history="currentTicket.ticket.history" @save="saveHistory()" class="p-mt-5" />
                <template #footer>
                    <div v-if="currentTicket.ticket.active" class="p-mt-4">
                        <Dropdown class="p-d-inlineflex p-ai-center p-mr-2 p-p-2" style="height: 37px; text-align: left; width: 350px;"  v-model="resolution" :options="resolutions" :placeholder="$t('Select a resolution to close this ticket')"  />
                        <Button class="p-mr-2 p-button-danger" :label="$t('Close ticket')" :disabled="!resolution" @click="closeTicket()"/>
                        <Button class="p-mr-2" :label="$t('Cancel')" @click="displayModal = false" />
                    </div>
                    <div v-else class="p-mt-4">
                        <Button class="p-mr-2 p-button-info" :label="$t('Reopen ticket')" @click="reopenTicket()"/>
                    </div>

                </template>
            </Dialog>
        </div>
	</div>
</template>

<script>
import ApiService from '../service/ApiService';
import FormViewer from '../components/FormViewer.vue';
import HistoryViewer from '../components/HistoryViewer.vue';


export default {
    name: "TicketManagement",
	props: {
	},
	data() {
		return {
            api: null,
            tickets: [],
            allTickets: [],
            directOpenTicket: null,
            filters: {
                "ticket.active": true
            },
            loading: true,
            adminUsersList: [],
            displayGenericDialog: false,
            genericDialogText: null,
            displayModal: false,
            alreadyExistPrompt: false,
            currentTicket: null,
            tempForm: {},
            sortForm: {},
            resolution: null,
            resolutions: [
                'completed',
                'canceled',
                'rejected'
            ]
		};
	},
    created(){
        this.api = new ApiService();
        this.reloadTickets();
        this.getUserAdmins();
        this.directOpenTicket = this.$route.query.hid;
    },
	methods: {
        roleToCountry(){
            const countries = [];
            if (this.$auth.user.allRoles.includes('dashboard-country-rw')) countries.push('rwanda');
            if (this.$auth.user.allRoles.includes('dashboard-country-na')) countries.push('namibia');
            if (this.$auth.user.allRoles.includes('dashboard-country-drc')) countries.push('drc');
            if (this.$auth.user.allRoles.includes('dashboard-country-za')) countries.push('zambia');
            if (this.$auth.user.allRoles.includes('dashboard-country-ug')) countries.push('uganda');
            if (this.$auth.user.allRoles.includes('dashboard-country-ca')) countries.push('canada');
            if (this.$auth.user.allRoles.includes('dashboard-country-usa')) countries.push('usa');
            if (this.$auth.user.allRoles.includes('dashboard-country-br')) countries.push('brazil');
            if (this.$auth.user.allRoles.includes('dashboard-country-in')) countries.push('india');
            return countries;
        },
        getUserAdmins(){
            this.api.getFromKc(`clients/`).then((clients) => {
                let realmManagementId = clients.filter(c => c.clientId === process.env.VUE_APP_KEYCLOAK_CLIENT)[0]?.id;
                this.api.getFromKc(`clients/${realmManagementId}/roles/dashboard-role-manager/users`).then((users) => {
                    this.adminUsersList = users.map((u,i) => {return {index: i+2, value: u.email, label: u.email}});
                    this.adminUsersList.some((u,i) => {
                        if(u.email === this.$auth.user.email){
                            this.adminUsersList[i].index = 1;
                            return true;
                        }
                    })
                    this.adminUsersList = this.adminUsersList.sort((a,b) => a.index - b.index);
                });
            });
        },
        setTicketAssignee(email) {
            this.loading = true;
            this.currentTicket.ticket.history.push({author:this.$auth.user.email,type:"activity",icon:"pi-play",timestamp:new Date().toISOString(),content:email?"Ticket assigned to {email}":"Ticket unassigned"})
            this.currentTicket.ticket.assignee = email;
            this.saveTicket();
        },
        filterTickets(){
            this.tickets = this.allTickets
            .filter(t => {
                return t.ticket.type !== '"platform_access_request"'
            })
            .filter(t => {
                if (this.filters['ticket.hid']) {
                    return t.ticket.hid.includes(this.filters['ticket.hid']);
                }
                return true;
            })
            .filter(t => {
                if (this.filters['ticket.type']) {
                    return t.ticket.type.includes(this.filters['ticket.type']);
                }
                return true;
            })
            .filter(t => {
                if (this.filters['ticket.assignee']) {
                    if (t.ticket.assignee) {
                        return t.ticket.assignee.includes(this.filters['ticket.assignee']);
                    }
                    return false;
                }
                return true;
            })
            .filter(t => {
                if (this.filters['ticket.active']) {
                    return t.ticket.active;
                }
                return true;
            })
        },
        reloadTickets(){
            this.api.getFromEp('tickets', {filters:JSON.stringify([{field: 'form.00010country.value', op: "in", value: this.roleToCountry()}])}).then((tickets) => {
                this.allTickets = tickets;
                this.filterTickets();
                this.loading = false;
                if (this.directOpenTicket) {
                    const tticket = this.allTickets.find(t => {
                        return t.ticket.hid === this.directOpenTicket;
                    });
                    if (tticket) {
                        this.showTicket(tticket);
                    }
                    this.directOpenTicket = null;
                }
            });
        },
        closeTicket(){
            this.loading = true;
            this.currentTicket.ticket.history.push({author:this.$auth.user.email,type:"activity",icon:"pi-play",timestamp:new Date().toISOString(),content:"Ticket closed by {email}"})
            this.currentTicket.ticket.active = false;
            this.currentTicket.ticket.resolution = this.resolution;
            this.saveTicket();
            this.currentTicket = null;
            this.displayModal = false;
        },
        reopenTicket(){
            this.loading = true;
            this.currentTicket.ticket.history.push({author:this.$auth.user.email,type:"activity",icon:"pi-play",timestamp:new Date().toISOString(),content:"Ticket re-opened by {email}"})
            this.currentTicket.ticket.active = true;
            this.saveTicket();
        },
        goToUser(email){
            this.api.getFromKc('users', {email}).then((user) => {
                if (Array.isArray(user) && user[0]?.id) {
                    window.open(`${process.env.VUE_APP_KEYCLOAK_URL}auth/admin/${process.env.VUE_APP_KEYCLOAK_REALM}/console/#/realms/${process.env.VUE_APP_KEYCLOAK_REALM}/users/${user[0].id}`, '_blank');
                }else{
                    this.genericDialogText = 'This user has not yet created his account. Please check the ticket for activity.';
                    this.displayGenericDialog = true;
                }
            });
        },
        showTicket(pl){
            this.currentTicket = pl;
            this.tempForm = pl.ticket.form;
            this.sortForm = {};
            Object.keys(this.tempForm)
            .sort((a,b) => {
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            })
            .forEach((k) => this.sortForm[k] = this.tempForm[k]);

            this.currentTicket.ticket.history.push({
              type: "activity",
              timestamp: new Date().toISOString(),
              icon: "pi-eye",
              author: this.$auth.user.email,
              content: this.$t("Ticket viewed by {email}", {email: this.$auth.user.email})
            });
            this.saveHistory(true);

            this.displayModal = true;
        },
        saveTicket() {
            this.currentTicket.ticket.lastActivity = Date.now();
            this.api.postToEp('tickets', this.currentTicket).then(() => {
                this.reloadTickets();
                    this.$toast.add({severity:'success', summary: this.$t('Success'), detail:this.$t('Ticket updated successfully'), life: 3000});
            })
        },
        saveHistory(silent) {
            this.currentTicket.ticket.lastActivity = Date.now();
            this.api.postToEp('tickets', this.currentTicket).then(() => {
                if (!silent) {
                    this.$toast.add({severity:'success', summary: this.$t('Success'), detail:this.$t('History updated successfully'), life: 3000});
                }
            })
        }
	},
	components: {
        FormViewer,
        HistoryViewer
	},
};
</script>

<style scoped>
.o-link{
    cursor: pointer;
}
.o-pill {
    background-color: #DEE2E6;
    border-radius: 15px;
    height: 30px;
    width: auto;
    cursor: pointer;
    font-weight: 600;
}

.p-invalid {
    color: #FF0000
}

/deep/ .p-paginator .p-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
